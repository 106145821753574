import arrowUp from "Assets/icons/arrow_up.svg";
import "./scrollToTop.scss";

const ScrollToTop = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <button
      className={
        window.scrollY > 50 ? "visibilityYes scrollToTop" : "visibilityNone"
      }
      onClick={scrollToTop}
    >
      <img src={arrowUp} className="icon" alt="Scroll to top" />
    </button>
  );
};

export default ScrollToTop;
