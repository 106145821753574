import { useNavigate } from "react-router-dom";

export const useScrollToElement = () => {
  const goTo = useNavigate();
  const windowHeigth = window.innerHeight;

  function scrollToElement(
    navigateTo: string,
    elementId: string,
    offsetFactor = 10
  ) {
    const offset = windowHeigth / offsetFactor;
    setTimeout(() => {
      let element = document.getElementById(elementId);
      goTo(navigateTo);

      if (!element) {
        setTimeout(() => {
          element = document.getElementById(elementId);
          if (!element) {
            return;
          }
          window.scrollTo({
            top: element.offsetTop - offset,
            behavior: "smooth",
          });
          return;
        }, 100);
      } else {
        window.scrollTo({
          top: element.offsetTop - offset,
          behavior: "smooth",
        });
      }
    }, 300);
  }
  return { scrollToElement } as const;
};
