import { useState } from "react";
import { NavLink } from "react-router-dom";
import SearchSelect from "../Search/Search";

import SelectLanguages from "../Language/Language";
import { ReactComponent as Lenguage } from "Assets/icons/icon-language.svg";
import { ReactComponent as Search } from "Assets/icons/icon-search.svg";
import Bars from "Assets/images/icon-bars-nav.png";
import Cross from "Assets/images/icon-cross-nav.png";
import Logo from "Assets/images/Logo-sin-slogan.png";
import Baipro from "Assets/images/Logo_baipro.png";

import { ClickAwayListener } from "@mui/base";

import { useTranslation } from "react-i18next";

import "./Navbar.scss";
import { useAppSelector } from "Hooks/useRedux";
import { useAppDispatch } from "Hooks/useRedux";
import { logout } from "Redux/authSlice";
import { useScrollToElement } from "Hooks/useScrollToElement";

type NavbarProps = {
  onChange: Function;
  isMobile: Boolean;
};

const Navbar: React.FC<NavbarProps> = ({ isMobile }) => {
  const { t } = useTranslation();
  const [openLanguage, setOpenLanguage] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const location = window.location.pathname;
  const { scrollToElement } = useScrollToElement();

  const [openSearch, setOpenSearch] = useState(false);

  const isAuthenticated = Boolean(useAppSelector((state) => state.auth.token));
  const role = useAppSelector((state) => state.auth.role);
  const dispatch = useAppDispatch();

  const handleOpenLanguage = () => {
    setOpenLanguage(!openLanguage);
  };
  const handleOpenSearch = () => {
    setOpenSearch(!openSearch);
    setOpenLanguage(false);
  };

  const handleClickAway = () => {
    setOpenSearch(false);
  };
  const handleClickAwayLenguage = () => {
    setOpenLanguage(false);
  };

  const handleLogout = () => {
    if (isAuthenticated) {
      dispatch(logout());
    }
  };

  return (
    <>
      {isMobile ? (
        <>
          <nav className="NavMobile">
            <div className="NavMobile-left">
              <NavLink to="/">
                <img
                  src={Logo}
                  alt="Logo de baipro"
                  className="NavMobile-left__Logo"
                />
              </NavLink>
              <NavLink to="/">
                <img
                  src={Baipro}
                  alt="Logo del texto"
                  className="NavMobile-left__logoText"
                />
              </NavLink>
            </div>

            <div className="NavMobile-right">
              <button
                onClick={handleOpenLanguage}
                aria-label="lenguage"
                className="NavMobile-right__btnLenguage"
              >
                <Lenguage />
                {openLanguage && <SelectLanguages />}
              </button>

              <button
                type="button"
                className="navBaipro_hamburger"
                aria-label="navbar"
                onClick={() => setOpenMenu(!openMenu)}
              >
                <img src={openMenu ? Cross : Bars} alt="toggle menu" />
              </button>
            </div>

            {openMenu && (
              <ul className="NavMobile-list">
                <li>
                  <NavLink to="/services">{t("header.services")}</NavLink>
                </li>
                <li>
                  <NavLink to="/projects">{t("header.projects")}</NavLink>
                </li>
                <li>
                  <NavLink to="/about">{t("header.about")}</NavLink>
                </li>
                <li>
                  <NavLink to="/enviroment">
                    {t("header.sustainability")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/careers">{t("header.careers")}</NavLink>
                </li>
                <li onClick={() => scrollToElement("/", "contact")}>
                  <NavLink to="/">{t("header.contact")}</NavLink>
                </li>
                <li>
                  <NavLink to={isAuthenticated ? "/" : "login"}>
                    <button onClick={handleLogout} className="btnLoginMobile">
                      {isAuthenticated ? "Cerrar sesión" : "Ingresar"}
                    </button>
                  </NavLink>
                </li>
              </ul>
            )}
          </nav>
        </>
      ) : (
        <>
          <nav className="navBaipro">
            <NavLink
              to="/"
              onClick={() => window.scroll(0, 0)}
              className="navBaipro-list_logo"
            >
              <img src={Logo} alt="Logo de baipro" />
            </NavLink>
            <ul className="navBaipro-list">
              <li>
                <NavLink to="/services">{t("header.services")}</NavLink>
              </li>
              <li>
                <NavLink to="/projects">{t("header.projects")} </NavLink>
              </li>
              <li>
                <NavLink to="/about">{t("header.about")} </NavLink>
              </li>
              <li>
                <NavLink to="/enviroment">{t("header.sustainability")}</NavLink>
              </li>

              <li>
                <NavLink to="/careers">{t("header.careers")} </NavLink>
              </li>
              <li onClick={() => scrollToElement("/", "contact")}>
                <NavLink to="/">{t("header.contact")} </NavLink>
              </li>
              {isAuthenticated && (
                <li>
                  <NavLink
                    to={
                      role === "marketing"
                        ? "/admin/marketing"
                        : "/admin/human-resources"
                    }
                  >
                    <span className="color-sky">Administrador</span>
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink to={isAuthenticated ? "/" : "login"}>
                  <button
                    onClick={handleLogout}
                    className="navBaipro-list_btnLogin"
                  >
                    {isAuthenticated ? "Cerrar sesión" : "Ingresar"}
                  </button>
                </NavLink>
              </li>
            </ul>
            <div
              className={
                location === "/" && window.scrollY < 50
                  ? "navBaipro-icon navBaipro-icon-white"
                  : "navBaipro-icon"
              }
            >
              <ClickAwayListener onClickAway={handleClickAwayLenguage}>
                <div className="navBaipro-icon_languages">
                  <button onClick={handleOpenLanguage} aria-label="lenguage">
                    <Lenguage />
                    {/* <img src={LanguageNeo} alt="Language" /> */}
                    {openLanguage ? <SelectLanguages /> : null}
                  </button>
                </div>
              </ClickAwayListener>
              <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={handleClickAway}
              >
                <div className="navBaipro-icon_search">
                  <button onClick={handleOpenSearch}>
                    <Search />
                    {openSearch ? <SearchSelect /> : null}
                  </button>
                </div>
              </ClickAwayListener>
            </div>
          </nav>
        </>
      )}
    </>
  );
};

export default Navbar;
